<template>
  <div class="px-10 -mt-10">
    <div flex="~ items-center justify-between" rounded="5" bg="white" p="l-4 r-3" class="shadow-[0_2px_8px_0px_rgba(122,122,122,0.25)] lg:h-20">
      <div flex="~ items-center col  lg:row 1" p="x-4 lg:0" h="full">
        <!-- 选择地址 -->
        <DesktopSearchDestination flex="1" w="full" :side-offset="16" :align-offset="-16" page-type="home" />
        <div class="hidden lg:block" w="0.25" m="x-4" bg="#B3B3B3" h="8" flex="shrink-0"></div>
        <div flex="1 ~ items-center" h="full" w="full" p="b-1 lg:b-0">
          <!-- 选择日期 -->
          <div flex="1 lg:initial">
            <DesktopSelectDate class="h-full" page-type="home">
              <template #default="{ formatDate }">
                <div p="3" cursor="pointer" rounded="xl" flex="~ items-center shrink-0">
                  <img :width="32" :height="32" src="~assets/icons/icon-calendar.png" />
                  <div
                    class="truncate text-lg"
                    :style="{
                      fontWeight: formatDate ? 500 : 400,
                      color: formatDate ? '#1B1B1B' : '#B1B1B1',
                    }"
                    m="l-4"
                  >
                    {{ formatDate || t('key.search.select.dates') }}
                  </div>
                </div>
              </template>
            </DesktopSelectDate>
          </div>
        </div>
      </div>
      <button
        m="l-4 y-3"
        p="x-7 y-4"
        cursor="pointer"
        class="block rounded-4 bg-black text-center"
        @click="handleSearch"
      >
        <div font="500" text="lg white">
          {{ t('key.attractions.search.todo') }}
        </div>
      </button>
    </div>
  </div>
</template>

<script lang="ts" setup>
const emit = defineEmits(['search'])
const { t } = useI18n()
const { handleHomeSearch } = useUseHomePopup(() => {
  emit('search')
})

function handleSearch() {
  handleHomeSearch()
}
</script>

<style>

</style>
