<template>
  <div w="full" relative bg="white">
    <div
      class="h-60 w-full" flex="~ justify-center items-center" p="b-12"
      :style="{
        background: 'center center no-repeat',
        backgroundSize: 'cover',
        backgroundColor: 'var(--tc-color-bg-index)',
        backgroundImage: `url(${bgImg})`,
      }"
    >
      <div class="px-6 text-center text-white font-600">
        <div class="whitespace-pre-wrap text-4xl leading-normal" :style="{ color: 'var(--tc-color-title-index)' }">
          {{ pageTitle }}
        </div>
        <div v-if="subTitle" class="mt-3 whitespace-pre-wrap text-xl font-400" :style="{ color: 'var(--tc-color-subtitle-index)' }">
          {{ subTitle }}
        </div>
      </div>
    </div>
    <div max-w="300" m="x-auto">
      <!-- 搜索框 -->
      <DesktopHomeSearch @search="handleSearch" />

      <!-- 合作品牌 -->
      <BusinessBPartnerBrands />

      <!-- 热门目的地 -->
      <DesktopPopularDestinations />

      <!-- 如何赚取 -->
      <BusinessBHowToEarn />
    </div>
  </div>
</template>

<script lang="ts" setup>
import ogImageUrl from '~/assets/logo.png'

const { t } = useI18n()

useSeoMeta({
  title: t('key.attractions.index.title'),
  description: t('key.attractions.index.descrition'),
  ogImageUrl,
})

const { pageTitle, subTitle, bgImg } = usePartnerConfig()
const { trackSearchClickEvent } = useTrackHome()

function handleSearch() {
  trackSearchClickEvent()
}
</script>
